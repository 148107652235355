window.erpl = {
    mainNav: {
        offset: $('#mainNav').offset(),
        height: $('#mainNav').height(),
    },
    breakpoints: {
        xs: 0,
        sm: 480,
        md: 640,
        lg: 750,
        xl: 1020,
        xxl: 1200
    },
    currentBreakpoint: function () {
        // Identification du breakpoint actuel
        var windowWidth = $(window).outerWidth();
        if (windowWidth < evostrap.breakpoints.sm) {
            return "xs";
        } else if ((windowWidth >= evostrap.breakpoints.sm) && (windowWidth < evostrap.breakpoints.md)) {
            return "sm";
        } else if ((windowWidth >= evostrap.breakpoints.md) && (windowWidth < evostrap.breakpoints.lg)) {
            return "md";
        } else if ((windowWidth >= evostrap.breakpoints.lg) && (windowWidth < evostrap.breakpoints.xl)) {
            return "lg";
        } else if ((windowWidth >= evostrap.breakpoints.xl) && (windowWidth < evostrap.breakpoints.xxl)) {
            return "xl";
        } else if (windowWidth >= evostrap.breakpoints.xxl) {
            return "xxl";
        } else {
            return null
        }
    },
    doModal: function (id, cssClass, title, body, showHeader, showFooter, size) {
        if (typeof showHeader === "undefined") { showHeader = true; }
        if (typeof showFooter === "undefined") { showFooter = true }
        if (typeof id === "undefined") { console.error("Aucun ID spécifié") }
        if (typeof cssClass === "undefined") { cssClass = "" }
        if (typeof title === "undefined") { title = "" }
        if (typeof body === "undefined") { body = "" }
        if (typeof size === "undefined") { size = "" }
        var html = '<div class="modal fade ' + cssClass + ' " id="' + id + '" tabindex="-1" role="dialog" aria-labelledby="' + id + 'ModalTitle" aria-hidden="true">';
        html += '<div class="modal-dialog modal-dialog-centered ' + size + '" role="document"> ';
        html += '<div class="modal-content">';
        // Header
        if (showHeader) {
            html += '<div class="modal-header d-none d-lg-flex">';
            html += '<h5 class="modal-title" id="membersresearchserviceModalTitle">' + title + '</h5 > ';
            html += '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
            html += '</div>';
        }
        // Body
        html += '<div class="modal-body">' + body + '</div>';
        //Footer
        if (showFooter) {
            html += '<div class="modal-footer d-none d-lg-flex"><button type="button" class="btn btn-default" data-dismiss="modal">Close</button></div>';
        }
        html += '</div>'; // Close modal-content
        html += '</div>'; // Close modal-dialog
        html += '</div>'; // Close modal
        return html;
    },
    loading: function (status) {
        if (status) {
            $('body').attr('data-loading-box', 'true');
            var loaderHtml = '<div class="erpl_loading-box d-flex position-fixed"><div class="spinner m-auto"></div></div>';
            $(loaderHtml).appendTo('body');
        } else {
            $('body').attr('data-loading-box', 'false');
            if ($('.erpl_loading-box').length) {
                $('.erpl_loading-box').remove();
            }

        }
    },
    key: {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        enter: 13,
        space: 32,
        ctrl: 91,
        shift: 16,
        escape: 27,
        tab: 9
    },
    simulateLanguage: function (sub, add) {
        var sub, add;
        if (!sub) {
            sub = 0
        }
        if (!add) {
            add = 0
        }
        $('body *').each(function () {
            if (this.childNodes.length) {
                if (this.childNodes[0].nodeValue) {
                    var val = this.childNodes[0].nodeValue
                    var from = val;
                    var to = "";
                    if (sub > 0) { from = val.substr(0, val.length - sub) }
                    if (add > 0) { to = val.substr(0, add) }
                    this.childNodes[0].nodeValue = from + to;
                }
            }
        })
    },
    getTouchPosition: function (touchStartEvent, callbackTouchStart, callbackTouchMove, callbackTouchEnd) {
        var obj = {}
        var startTime;
        var endTime;
        $(document).on('touchstart.touchPosition touchend.touchPosition touchmove.touchPosition', function (e) {
            if (!startTime) {
                obj.touchStartX = touchStartEvent.changedTouches[0].clientX;
                obj.touchStartY = touchStartEvent.changedTouches[0].clientY;
                startTime = new Date();
                if (callbackTouchStart) {
                    callbackTouchStart(obj);
                }
            } else if (e.type === "touchmove") {
                obj.touchMoveX = e.changedTouches[0].clientX;
                obj.touchMoveY = e.changedTouches[0].clientY;
                obj.distanceMoveX = obj.touchMoveX - obj.touchStartX
                obj.distanceMoveY = obj.touchStartY - obj.touchMoveY
                endTime = new Date();
                obj.duration = endTime - startTime;
                if (callbackTouchMove) {
                    callbackTouchMove(obj);
                }
            } else if (e.type === "touchend") {
                obj.touchEndX = e.changedTouches[0].clientX;
                obj.touchEndY = e.changedTouches[0].clientY;
                obj.distanceEndX = obj.touchEndX - obj.touchStartX
                obj.distanceEndY = obj.touchStartY - obj.touchEndY
                endTime = new Date();
                obj.duration = endTime - startTime;
                if (callbackTouchEnd) {
                    callbackTouchEnd(obj);
                }
                $(document).off('.touchPosition')
            }
        });
        return obj;
    },
    setCookie: function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie: function (cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    checkCookie: function (cname) {
        var cookie = this.getCookie(cname);
        if (cookie != "") {
            return true
        } else {
            return false
        }
    }
}
var erpl = window.erpl;