
interface EsIconItem {
    name: string;
    width: string;
    height: string;
}


interface EsIconGetRequest {
	name: string;
	size ? : "16" | "24" | "32" | "48" | "64";
	transform ? : 'flip-x' | 'flip-y' | 'rotate-90' | 'rotate-180' | 'rotate-270';
	title ? : string
}

export class EsIcon {
    list: EsIconItem[] = [];

    constructor(list:EsIconItem[]) {
        this.list = list;
    }


    get ( req : EsIconGetRequest | string ) {
        let name:EsIconGetRequest['name'], size:EsIconGetRequest["size"], transform:EsIconGetRequest['transform'], title:EsIconGetRequest['title'];

        if (typeof req === "string") {
            name = req;
        } else {
           ({ name, size, transform, title } = req);
        }

        let icoData = this.list.find(icon => {

            return icon.name == name
        });

        if (!icoData) {
            return Error("Icon not found")
        }

        let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
        svg.setAttribute('class', `es_icon es_icon-${icoData.name}`)
        let use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
        use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#es_icon-${icoData.name}`)
        svg.appendChild(use)

        if (size) {
            svg.classList.add(`es_icon-${size}`);
        }

        if (transform) {
            svg.classList.add(`es_icon-${transform}`);
        }

        if (title) {
            let titleElement = document.createElementNS('http://www.w3.org/2000/svg', 'title');
            titleElement.innerHTML = title;
            svg.insertAdjacentElement('afterbegin', titleElement)

        } else {
            svg.setAttribute('aria-hidden', 'true');
        }

        return svg;
        
    }
    
}