
/** EVOSTRAP */

__webpack_public_path__ = document.getElementById('evostrap') ? document.getElementById('evostrap').getAttribute('src').split('js/evostrap.js')[0] : '/evostrap/dist/';


// Objet de gestion async des scripts
window.evostrap = {
    load: {},
    messages: {
        notLoaded: `Les conditions de chargement du script ne sont pas valide. Pour forcer le chargement, passer le paramètre forceLoading à true`
    }
};

/** EvostrapErpl
 * @deprecated
 * alias temporaire
 */

window.evostrapErpl = window.evostrap;


// import() nécessite l'importation des polyfills promise et iterator avant la transpilation babel pour fonctionner sur les anciens navigateurs
// https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import
// import "core-js/modules/es6.promise";
// import "core-js/modules/es6.array.iterator";

// Import evostrap base
import '../../evostrap-core/src/js/index';

// Intégration de datepicker et selectWoo
import '../../lib/datepicker/datepicker';
import '../../lib/selectwoo/selectWoo.full';

// POLYFILL - PROTOTYPE - PLUGIN 
import './erpl_modules/polyfill';


// ERPL toInputList
evostrap.load.toInputList = function() {
    return new Promise((resolve, reject) => {

        if ($('.erpl_to-input-list').length) {
            import ( /* webpackChunkName: "js/erpl-to-input-list" */ './erpl_modules/erpl-to-input-list').then(val => {
                resolve(val)
            }).catch((reason) => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

// ScrollTo - JQuery
jQuery.fn.scrollTo = function(elem, speed, offsetTop) {
    if (!offsetTop) {
        offsetTop = 0;
    }
    $(this).animate({
        scrollTop: $(this).scrollTop() - $(this).offset().top + $(elem).offset().top + offsetTop
    }, speed == undefined ? 1000 : speed);
    return this;
};


//========================================================================================
/*                                                                                      *
 *                                 DEFINITIONS GLOBALES                                 *
 *                                                                                      */
//========================================================================================
import "./erpl_modules/erpl"


// Merge avec objet global evostrap
window.evostrap = {...window.evostrap, ...window.erpl}
//========================================================================================
/*                                                                                      *
 *                                          WAI                                         *
 *                                                                                      */
//========================================================================================
// Focus sur le sélecteur de langue depuis le menu Shortcuts. 
evostrap.load.initWai = function() {
    $('.erpl_waiaccess a[href="#erpl_topbar-language"]').on('click', function(e) {
        e.preventDefault()
        $('#erpl_topbar-language').select2('focus')
    })

}


//========================================================================================
/*                                                                                      *
 *                                     COOKIE POLICY                                    *
 *                                                                                      */
//========================================================================================

evostrap.load.erplPolicyCookie = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('.erpl_policy-cookie').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-policy-cookie" */ './erpl_modules/erpl-policy-cookie').then(val => {
                resolve(val)
            }).catch((reason) => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })

}


//========================================================================================
/*                                                                                      *
 *                                       ES_SELECT                                      *
 *                                                                                      */
//========================================================================================

evostrap.load.esSelect = function(forceLoading) {
    return new Promise(async (resolve, reject) => {
        if ($('.es_select').length || forceLoading) {
            import ( /* webpackChunkName: "js/es-select/es-select" */ './erpl_modules/es_select/js_dist/es_select').then(Module => {
                console.log(Module);
                resolve(Module.EsSelect)
                if (!forceLoading)
                document.querySelectorAll('.es_select').forEach(elSelect => {

                    new Module.EsSelect(elSelect, {
                        minLengthForSearch : 20,
                        icons: {
                            arrow: {
                                default: `<i class="erpl_icon erpl_icon-arrow-bottom-dark"></i>`,
                                active: `<i class="erpl_icon erpl_icon-arrow-bottom-blue rotate-180"></i>`
                            },
                            search: {
                                default: `<i class="erpl_icon erpl_icon-search-light"></i>`,
                                active: `<i class="erpl_icon erpl_icon-search-light"></i>`
                            },
                            clear: {
                                default : `<i class="erpl_icon erpl_icon-close-light"></i>`
                            }
                    
                        }
                    });
                })
            }).catch((reason) => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })

}


evostrap.load.esSelectOrgan = function(forceLoading) {
    return new Promise(async (resolve, reject) => {
        if ($('.es_select-organ').length || forceLoading) {

            import ( /* webpackChunkName: "js/es-select/es-select" */ './erpl_modules/es_select/js_dist/es_select').then(Module => {
                resolve(Module.EsSelect);

                if (!forceLoading)
                document.querySelectorAll('.es_select-organ').forEach((selectEl) => {

                    new Module.EsSelect(selectEl, {
                        minLengthForSearch : 20,
                        itemTemplate: (optionElement, i) => {
                            let organText = optionElement.dataset.organText || optionElement.dataset.additionaltext || "";
                            let organClass = optionElement.dataset.organClass || optionElement.dataset.additionaltextClass || "erpl_badge-outline-red";
                            return `<li role="option" aria-selected="false" class="es_select-options-item" tabindex="-1"> <span class="es_select-options-organ erpl_badge ${organClass} ml-{{organLevel}} mr-25">${organText}</span> <span>{{label}}</span></li>`
                        }
                    });

                });

            }).catch((reason) => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })

}


evostrap.load.esSelectMeps = function(forceLoading) {
    return new Promise(async (resolve, reject) => {
        if ($('.es_select-meps').length || forceLoading) {

            import ( /* webpackChunkName: "js/es-select/es-select" */ './erpl_modules/es_select/js_dist/es_select').then(Module => {
                resolve(Module.EsSelect);
                
                if (!forceLoading)
                document.querySelectorAll('select.es_select-meps').forEach(mepsSelectElement => {
                    console.log(mepsSelectElement);
                    console.log("__webpack_public_path__", __webpack_public_path__);
                    let source = mepsSelectElement.dataset.source || `${__webpack_public_path__}../assets/data/meps-list.json`;
                    console.log(source);
                    $.ajax({
                        dataType: "json",
                        url: source,
                        success: (mepsJSON) => {

                    
                            mepsJSON.forEach((mep) => {
                                mepsSelectElement.appendChild($(`<option value="${mep.id}" data-id="${mep.id}">${mep.firstName} ${mep.upperLastName}</option>`)[0]);
                            })

                            new Module.EsSelect(mepsSelectElement, {
                                itemTemplate: (optionElement , i ) => {
                                    
                                    if (optionElement.dataset.id) {
                                        return `<li role="option" class="es_select-options-item" tabindex="-1"> <span class="es_select-mep-picture"><img loading="lazy" src="https://www.europarl.europa.eu/mepphoto/{{id}}.jpg" alt=""/></span> <span>{{label}}</span></li>`
                                    } else {
                                        return `<li role="option" class="es_select-options-item" tabindex="-1"><span>{{label}}</span></li>`
                                    }
                                    
                                }

                            });
                    
                        }
                      })

                      
                })




                
            }).catch((reason) => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })

}

//========================================================================================
/*                                                                                      *
 *                                        Divers                                        *
 *                                                                                      */
//========================================================================================

//──── SELECT2 - SELECTWOO ───────────────────────────────────────────────────────────────


evostrap.load.initSelect2 = function() {


    let lang = $('html').attr('lang') || "en";
    
    // Select2 / SelectWoo: Header Delegation
    function select2TemplateOrgan(option) {
        if (!option.id) {
            return option.text;
        }

        let organLevel = (parseInt(option.element.dataset.organLevel) * 2).toString() || "0";

        if (!option.element.dataset.additionaltext) {
            var $option = $(
                '<span class="pl-' + organLevel + '">' + option.text + '</span>'
            );
        } else {

            var badgeClass = option.element.dataset.additionaltextClass || 'erpl_badge-outline-red';
            var $option = $(
                `<span class="pl-${organLevel}"><span class="erpl_badge ${badgeClass} d-inline-block mr-25">${option.element.dataset.additionaltext}</span>${option.text}</span>`
            );
        }

        return $option;
    };

    import(/* webpackChunkName: "js/select2/i18n/[request]" */ `../../lib/selectwoo/i18n/${lang}`).then(languageFile => {

        // Basic init
            $('.select2').select2({
                language: languageFile.default
            });

            $('.select2-committee, .select-organ').select2({
                templateResult: select2TemplateOrgan,
                language: languageFile.default
            });
        
            $('.select2-committee, .select-organ').on('select2:open', function(e) {
                // Calcul de la hauteur maximale
                var id = '#select2-' + $(this).attr('id') + '-results';
                $(id).css('max-height', $(window).height() - $(id)[0].getBoundingClientRect().y - 20)
            });

        // Reset le champ lors d'un événement reset dans le form. 
        $('select.select2, select.select2-committee, select.select-organ').each(function() {

            let el = $(this)[0];
            let form = $(el).closest('form')[0];

            if (form) {
                form.addEventListener('reset', (e) => {
                    $(el).val('').trigger('change')
                })
            }
        })
        
    })
    


    

}


//──── AIDE A L OPTIMISATION DES STYLES SANS JS ──────────────────────────────────────────


evostrap.load.jsHelper = function() {
    $('body').attr('data-js', 'true');

}


//──── Popovers et Tooltip everywhere ────────────────────────────────────────────────────


evostrap.load.initTooltips = function() {
        $(function() {
            $('[data-toggle="popover"]').popover()
        })
        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        })

        $(function() {
            $('[data-toggle="popover-html"]').popover({
                html: true
            })
        })

    }
    //──── DATEPICKER ────────────────────────────────────────────────────────────────────────


evostrap.load.initDatepicker = function() {


    $('[data-toggle="datepicker"]').each((i, datepickerInputElement) => {

        let $triggerButton = $(datepickerInputElement).next('.input-group-append:not(.disabled)').find('.btn');

        let lang = $(datepickerInputElement).attr('data-lang') || document.querySelector('html').getAttribute('lang') || 'en'


        import(/* webpackChunkName: "js/datepicker/i18n/[request]" */ `../../lib/datepicker/i18n/${lang}`).then(languageFile => {
            console.log(languageFile);

            $(datepickerInputElement).datepicker({
                autoHide: true,
                zIndex: 1050,
                trigger: $triggerButton.length ? $triggerButton : null,
                language: lang
            });
        })



        if (!$triggerButton.length) {
            console.warn(datepickerInputElement, "Veuillez ajouter un bouton déclencheur")
        }
    })
}

//──── Session selector ──────────────────────────────────────────────────────────────────

evostrap.load.erplSessionSelector = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('[data-toggle="session"]').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-session-selector" */ './erpl_modules/erpl-session-selector').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}


//──── Clearable ─────────────────────────────────────────────────────────────────────────

evostrap.load.initClearableInput = function() {
    $('[data-clearable="true"]').on('keyup', function() {
        var $input = $(this);

        if ($input.val().length) {
            $input.addClass('clearable');
            $input.next('.input-group-append').one('click', function() {
                $input.val('').trigger('keyup');
                $input[0].dispatchEvent(new CustomEvent('change'))
                $input.removeClass('clearable');
            })
        } else {
            $input.removeClass('clearable');

        }


    });

}


//──── ERPL MOVE ─────────────────────────────────────────────────────────────────────────

evostrap.load.erplMove = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_move').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-move" */ './erpl_modules/erpl-move').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//──── SMOOTH SCROLL ─────────────────────────────────────────────────────────────────────

evostrap.load.erplSmoothScroll = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_smooth-scroll').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-header" */ './erpl_modules/erpl-smooth-scroll').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

//──── MEP AUTOCOMPLETE ─────────────────────────────────────────────────────────────────────

evostrap.load.erplAutocompleteMeps = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_autocomplete-meps').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-autocomplete-meps" */ './erpl_modules/erpl-autocomplete-meps').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}


//========================================================================================
/*                                                                                      *
 *                                        HEADER                                        *
 *                                                                                      */
//========================================================================================


evostrap.load.erplHeader = function(forceLoading) {
    return new Promise((resolve, reject) => {
        console.log(document.querySelectorAll('.erpl_header'));
        if (document.querySelectorAll('.erpl_header').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-header/erpl_header" */ './erpl_modules/erpl_header/js_dist/erpl-header').then(Module => {
                resolve(Module.ErplHeader);
                console.log(Module);

                if (!forceLoading) {
                    document.querySelectorAll('.erpl_header').forEach(el => {

                        new Module.ErplHeader(el)

                    })
                }
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}



//========================================================================================
/*                                                                                      *
 *                                        FOOTER                                        *
 *                                                                                      */
//========================================================================================



evostrap.load.erplFooter = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('footer').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-footer" */ './erpl_modules/erpl-footer').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//========================================================================================
/*                                                                                      *
 *                                     ERPL EUROMAP                                     *
 *                                                                                      */
//========================================================================================
// window.erplAnimateEuMap;

evostrap.load.erplEuroMap = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_map-europe').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-map-europe" */ './erpl_modules/erpl-map-europe').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//========================================================================================
/*                                                                                      *
 *                                     ERPL Animate                                     *
 *                                                                                      */
//========================================================================================


evostrap.load.erplAnimate = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_animate').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-animate" */ './erpl_modules/erpl-animate').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

//========================================================================================
/*                                                                                      *
 *                                   ERPL Tree Select                                   *
 *                                                                                      */
//========================================================================================


evostrap.load.erplTree = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_tree').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-tree" */ './erpl_modules/erpl-tree').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

//========================================================================================
/*                                                                                      *
 *                                     ERPL DROPDOWN                                    *
 *                                                                                      */
//========================================================================================


evostrap.load.erplDropdown = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_dropdown').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-dropdown" */ './erpl_modules/erpl-dropdown').then(module => {
                resolve($.fn.erplDropdown)

                if (!forceLoading) {

                    $('.erpl_dropdown').each(function () {

                        $(this).erplDropdown();
                    })
                    
                }
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}


//========================================================================================
/*                                                                                      *
 *                                     ERPL REFINER                                     *
 *                                                                                      */
//========================================================================================


evostrap.load.erplRefiner = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_refiner').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-refiner" */ './erpl_modules/erpl-refiner').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}


//========================================================================================
/*                                                                                      *
 *                                  ERPL CHECKBOX LIST                                  *
 *                                                                                      */
//========================================================================================


evostrap.load.erplCheckboxList = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_checkbox-list').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-checkbox-list" */ './erpl_modules/erpl-checkbox-list').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });

        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//========================================================================================
/*                                                                                      *
 *                                    FORM VALIDATION                                   *
 *                                                                                      */
//========================================================================================
// jQuery Validation


evostrap.load.erplFormValidation = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_form-validation').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-form-validation" */ './erpl_modules/erpl-form-validation').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//========================================================================================
/*                                                                                      *
 *                                    ERPL FORM STEPS                                   *
 *                                                                                      */
//========================================================================================


evostrap.load.erplFormSteps = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_form-steps').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-form-steps" */ './erpl_modules/erpl-form-steps').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

//========================================================================================
/*                                                                                      *
 *                        Erpl_full-width-banner-section parallax                       *
 *                                                                                      */
//========================================================================================

evostrap.load.erplFullWidthBannerSection = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_full-width-banner-section').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-full-width-banner-section" */ './erpl_modules/erpl-full-width-banner-section').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            });
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//========================================================================================
/*                                                                                      *
 *                                 ERPL FORM AUTOSUBMIT                                 *
 *                                                                                      */
//========================================================================================

evostrap.load.initAutoSubmit = function() {

    $('form[data-autosubmit="true"]').on('change', function() {
        $(this).trigger('submit');
    })

}

//========================================================================================
/*                                                                                      *
 *                                 ERPL TABLE RESPONSIVE                                *
 *                                                                                      */
//========================================================================================


evostrap.load.erplTableResponsive = function(forceLoading) {
        return new Promise((resolve, reject) => {

            if ($('.table.erpl_table-responsive[data-responsive-breakpoint]').length || forceLoading) {
                import ( /* webpackChunkName: "js/erpl-table-responsive" */ './erpl_modules/erpl-table-responsive').then(val => {
                    resolve(val)
                }).catch(reason => {
                    reject(reason)
                });

            } else {
                resolve(evostrap.messages.notLoaded)
            }


        })
    }
    //========================================================================================
    /*                                                                                      *
     *                                 ERPL SIDE SEARCH FORM                                *
     *                                                                                      */
    //========================================================================================
    // Déplacé vers side-search-form.js


//========================================================================================
/*                                                                                      *
 *                                    ERPL SLIDESHOW                                    *
 *                                                                                      */
//========================================================================================



evostrap.load.erplSlideshow = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_slideshow').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-slideshow" */ './erpl_modules/erpl-slideshow').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

//========================================================================================
/*                                                                                      *
 *                                Scrollbar personnalisée                               *
 *                                                                                      */
//========================================================================================


evostrap.load.erplScrollbar = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_scrollbar').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-scrollbar" */ './erpl_modules/erpl-scrollbar').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }

    })
}

//========================================================================================
/*                                                                                      *
 *                                    ERPL TAGS INPUT                                   *
 *                                                                                      */
//========================================================================================


evostrap.load.erplTagsInput = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('.erpl_tags-input').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-tags-input" */ './erpl_modules/erpl-tags-input').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })

}

//========================================================================================
/*                                                                                      *
 *                                     ERPL CAPTCHA                                     *
 *                                                                                      */
//========================================================================================


evostrap.load.erplCaptcha = function(forceLoading) {
    return new Promise((resolve, reject) => {

        if ($('.erpl_captcha').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-captcha" */ './erpl_modules/erpl-captcha').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }


    })
}

//========================================================================================
/*                                                                                      *
 *                                       HEMICYCLE                                      *
 *                                                                                      */
//========================================================================================

evostrap.load.erplHemicycle = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('.erpl_hemicycle').length || forceLoading) {
            import ( /* webpackChunkName: "js/widgets/hemicycle/hemicycle" */ './../../widgets/hemicycle/js/hemicycle').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

//========================================================================================
/*                                                                                      *
 *                                     SPLIT SCREEN                                     *
 *                                                                                      */
//========================================================================================

evostrap.load.erplSplitScreen = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('.erpl_split-screen').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-split-screen" */ './erpl_modules/erpl-split-screen').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

//========================================================================================
/*                                                                                      *
 *                                      FORM STATUS                                     *
 *                                                                                      */
//========================================================================================

evostrap.load.erplFormStatus = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('.erpl_form-status').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-form-status" */ './erpl_modules/erpl-form-status').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

//========================================================================================
/*                                                                                      *
 *                                POSITION STICKY RELATED                               *
 *                                                                                      */
//========================================================================================

evostrap.load.erplPositionStickyRelated = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('[data-position-sticky-related]').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-position-sticky-related" */ './erpl_modules/erpl-position-sticky-related').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}


//========================================================================================
/*                                                                                      *
 *                                     CONTEXT MENU                                     *
 *                                                                                      */
//========================================================================================
evostrap.load.erplContextMenu = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if (forceLoading) {
            import ( /* webpackChunkName: "js/erpl-context-menu" */ './erpl_modules/erpl-context-menu').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

//========================================================================================
/*                                                                                      *
 *                                     DRAG AND DROP                                    *
 *                                                                                      */
//========================================================================================
evostrap.load.erplDragAndDrop = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if (forceLoading) {
            import ( /* webpackChunkName: "js/erpl-drag-and-drop" */ './erpl_modules/erpl-drag-and-drop').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

//========================================================================================
/*                                                                                      *
 *                                     NOTIFICATION                                     *
 *                                                                                      */
//========================================================================================
evostrap.load.erplToast = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if (forceLoading) {
            import ( /* webpackChunkName: "js/erpl-toast" */ './erpl_modules/erpl-toast').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

//========================================================================================
/*                                                                                      *
 *                                       ACCORDION                                      *
 *                                                                                      */
//========================================================================================
evostrap.load.erplAsync = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if ($('[data-async-src]').length || forceLoading) {
            import ( /* webpackChunkName: "js/erpl-async-data" */ './erpl_modules/erpl-async-data').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}




//========================================================================================
/*                                                                                      *
 *                                     VIDEO                                            *
 *                                                                                      */
//========================================================================================
evostrap.load.erplVideo = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if (forceLoading || document.querySelectorAll('.erpl_video video').length) {
            import ( /* webpackChunkName: "js/erpl-video" */ './erpl_modules/erpl_video/js_dist/erpl_video').then(Module => {
                resolve(Module)

                if (!forceLoading)
                document.querySelectorAll('.erpl_video').forEach(erplVideoElement => {

                    if (erplVideoElement.querySelector('video')) {
                        new Module.ErplVideo(erplVideoElement)
                    }
                })


            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}


/* Search FORM */
evostrap.load.erplSearchForm = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if (forceLoading || (document.querySelector('.erpl_search-form-sidebar'))) {
            import ( /* webpackChunkName: "js/erpl_search-form" */ './erpl_modules/erpl_search-form/js_dist/erpl_search-form').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

/* Search FORM LIGHT */
evostrap.load.erplSearchFormLight = function(forceLoading) {
    return new Promise((resolve, reject) => {
        if (forceLoading || (document.querySelector('.erpl_search-form-sidebar-light'))) {
            import ( /* webpackChunkName: "js/erpl_search-form-light" */ './erpl_modules/erpl_search-form-light/js_dist/erpl_search-form-light').then(val => {
                resolve(val)
            }).catch(reason => {
                reject(reason)
            })
        } else {
            resolve(evostrap.messages.notLoaded)
        }
    })
}

/** es_icons */
evostrap.icon = new (require('./erpl_modules/es_icon/ts_src/es_icon.ts').EsIcon)(require('evostrap-icons/dist/es_icon/es_icon.json'));
evostrap.load.esIcons = function(forceLoading) {
    return new Promise((resolve, reject) => {



        import(/* webpackChunkName: "assets/esIcons" */ '!raw-loader!evostrap-icons/dist/es_icon/sprites.svg').then(spriteModule => {

            let sprite = spriteModule.default;
            document.body.insertAdjacentHTML("afterbegin", sprite);

            resolve(sprite)


        }).catch(err => reject(err))

        
    })
}


// Chargement de l'ensemble des scripts
$(function() {
    $(Object.keys(evostrap.load)).each(function() {
        evostrap.load[this]();
    })

});